import React, { useState, useEffect, useRef, useCallback } from "react";
import "src/components/index.css";
import Navbar from "src/components/Navbar";
import { ShoppingCart, X } from "react-feather";
import { useOverlay } from "src/components/OverlayProvider";
import MenuSeparator from "src/components/MenuSeparator";
import LanguageDropdown from "src/components/LanguageDropdown";
import { useAppContext } from "src/components/LanguageContext";
import { useAppContext as useCartCountContext } from "src/components/CartCountContext";
import { useSocket } from "src/components/SocketContext";
import fetchCartCount from "src/components/helpers/fetchCartCount";
import { Navigate, useNavigate } from "react-router-dom";
import formatPrice from "src/components/helpers/formatPrice";
import { Helmet } from "react-helmet";
import AdminNavbar from "./AdminNavbar";

interface ScreenBaseProps {
  children: React.ReactNode;
  title?: string;
}

const ScreenBase: React.FC<ScreenBaseProps> = ({ children, title }) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isContentVisible, setIsContentVisible] = useState(false);
  const [backgroundVisible, setBackgroundVisible] = useState(false);

  const {
    overlayVisible,
    cartOverlayVisible,
    toggleOverlay,
    toggleCartOverlay,
  } = useOverlay();

  const overlayContentRef = useRef<HTMLDivElement>(null);
  const cartOverlayContentRef = useRef<HTMLDivElement>(null);
  const overlayBackgroundRef = useRef<HTMLDivElement>(null);

  const { state: languageState, dispatch: languageDispatch } = useAppContext();
  const { state: cartState, dispatch: cartDispatch } = useCartCountContext();
  const socket = useSocket();
  const navigate = useNavigate();

  const handleScroll = useCallback(() => {
    setIsScrolled(window.scrollY > 0);
  }, []);

  const handleOverlayTransitionEnd = useCallback(
    (e: TransitionEvent) => {
      if (
        e.target === overlayContentRef.current ||
        e.target === cartOverlayContentRef.current
      ) {
        setIsContentVisible(overlayVisible || cartOverlayVisible);

        if (!overlayVisible && !cartOverlayVisible) {
          setBackgroundVisible(false);
        }
      }
    },
    [overlayVisible, cartOverlayVisible]
  );

  const handleOverlayBackgroundClick = useCallback(
    (event: React.MouseEvent) => {
      if (
        overlayContentRef.current?.contains(event.target as Node) ||
        cartOverlayContentRef.current?.contains(event.target as Node)
      ) {
        return;
      }

      if (cartOverlayVisible) {
        toggleCartOverlay();
      } else if (languageState.dropdownOpen) {
        languageDispatch({ type: "TOGGLE_DROPDOWN" });
      } else {
        toggleOverlay();
      }
    },
    [
      cartOverlayVisible,
      languageState.dropdownOpen,
      toggleCartOverlay,
      toggleOverlay,
      languageDispatch,
    ]
  );

  useEffect(() => {
    const fetchCartData = async () => {
      try {
        const resp = await fetchCartCount();
        cartDispatch({
          type: "SET_CART_COUNT",
          payload: {
            count: resp.data.items.count,
            items: resp.data.items.items,
            finalValue: resp.data.items.finalValue,
            total: resp.data.items.total,
          },
        });
      } catch (e) {
        console.error("Failed to fetch cart count", e);
      }
    };

    fetchCartData();
  }, [cartDispatch]);

  useEffect(() => {
    if (socket) {
      const updateCart = (data: any) => {
        cartDispatch({
          type: "SET_CART_COUNT",
          payload: {
            count: data.count,
            items: data.items,
            finalValue: data.finalValue,
            total: data.total,
          },
        });
      };

      socket.on("cartItemCount", (data) => {
        updateCart(data)});

      return () => {
        socket.off("cartItemCount", updateCart);
      };
    }
  }, [socket]);

  const showCheckmark = (testLang: string, currentLang: string) => {
    switch (testLang) {
      case "en-gb":
        if (currentLang === "en-gb") {
          return true;
        }
        break;
      case "lv":
        if (currentLang === "lv") {
          return true;
        }
        break;
      case "ru":
        if (currentLang === "ru") {
          return true;
        }
        break;
      default:
        return false;
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    const overlayContent = overlayContentRef.current;
    const cartOverlayContent = cartOverlayContentRef.current;

    overlayContent?.addEventListener(
      "transitionend",
      handleOverlayTransitionEnd
    );
    cartOverlayContent?.addEventListener(
      "transitionend",
      handleOverlayTransitionEnd
    );

    return () => {
      overlayContent?.removeEventListener(
        "transitionend",
        handleOverlayTransitionEnd
      );
      cartOverlayContent?.removeEventListener(
        "transitionend",
        handleOverlayTransitionEnd
      );
    };
  }, [handleOverlayTransitionEnd]);

  useEffect(() => {
    if (overlayVisible || cartOverlayVisible) {
      setBackgroundVisible(true);
    } else {
      setIsContentVisible(false);
    }
  }, [overlayVisible, cartOverlayVisible]);


  const renderCartItems = () => {
    if (!cartState.items || cartState.items.length === 0) {
      return (
        <div className="flex flex-grow flex-col justify-center items-center text-center my-4">
          <p className="text-accent mb-4 font-semibold text-lg">No items in the cart</p>
          <a
            href="/shop/all"
            className="flex !select-none no-underline px-3 text-base !text-first select-none cursor-pointer border-1 bg-second border-second rounded-full py-2.5 font-semibold"
          >
            Continue shopping
          </a>

        </div>
      );
    }
  
    return cartState.items.map((item, index) => (
      <div key={index} className="flex flex-row my-4">
        <div className="relative">
          <div className="flex rounded-lg !w-20 !h-20 bg-accent"></div>
          <div className="absolute -top-2 -right-2 m-1 bg-second text-white !text-sm font-semibold !border-second rounded-full w-6 h-6 flex items-center justify-center text-xs">
            {item.quantity}
          </div>
        </div>
  
        <div className="flex flex-col flex-grow font-semibold text-lg mx-3">
          <a href={`/product/${item.glob}/reviews`} className="cursor-pointer no-underline w-full hover:underline !text-accent text-base">
            {item.name[languageState.language]}
          </a>
          <div className="text-accent font-normal text-base">
            {formatPrice(item.total.valueAfterDiscount)}
          </div>
        </div>
      </div>
    ));
  };
  
  return (
    <div className="containers">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <div className="content">
        <header className="header !bg-first">
          <AdminNavbar data={[]} />
        </header>
        {!isScrolled && (
          <div className="flex justify-center items-center !bg-second !text-first font-normal p-2">
            <ShoppingCart />
            Free shipping on orders over €50
          </div>
        )}
        <div className="app-contents !bg-first">{children}</div>

        {(backgroundVisible || languageState.dropdownOpen) && (
          <div
            className={`overlay-background visibel ${
              languageState.dropdownOpen ? "!z-2000 md:!block" : "md:hidden"
            }`}
            onClick={handleOverlayBackgroundClick}
            ref={overlayBackgroundRef}
          ></div>
        )}

        <div
          ref={cartOverlayContentRef}
          className={`cart-overlay-content ${
            cartOverlayVisible
              ? "expanded sm:!w-1/2 lg:!w-5/12 border border-accent border-opacity-55"
              : ""
          }`}
        >
          {isContentVisible && (
            <div className="flex h-full flex-grow flex-col px-8 py-6">
              <div className="flex flex-row justify-between">
                <div className="text-2xl font-bold !text-accent self-center flex flex-row">
                  Your cart
                  <span className="mx-2 text-sm border text-first bg-second rounded-full border-accent w-8 h-8 flex items-center justify-center">
                    {cartState.cartCount}
                  </span>
                </div>

                <button
                  onClick={toggleCartOverlay}
                  className="rounded-full py-2"
                >
                  <X />
                </button>
              </div>

              <div className="flex flex-col flex-grow my-6 overflow-scroll">
                {renderCartItems()}
              </div>

              <div className="text-xl font-bold !text-accent flex w-full justify-between self-start">
                <span className="text-xl !text-accent">Total</span>
                <span className="text-xl font-bold !text-accent">
                  {formatPrice(cartState.total.totalAfterDiscount)}
                </span>
              </div>

              <div className="flex flex-row justify-between mt-6">
                <button
                  onClick={() => {
                    navigate("/cart");
                    toggleCartOverlay();
                  }}
                  className="flex flex-grow mr-2 !p-0.5 my-2 nd:my-0 nd:mr-2 items-center justify-center !text-accent cursor-pointer !text-base !font-semibold !border !border-accent !rounded-md py-3 px-4 nd:!py-2"
                >
                  View cart
                </button>
                <button
                  onClick={() => {
                    navigate("/confirm-checkout");
                    toggleCartOverlay();
                  }}
                  className="!p-0.5 flex flex-grow my-2 nd:my-0 !text-accent nd:ml-2 cursor-pointer !text-base !text-first !bg-second !font-semibold !border !border-second !rounded-md py-3 px-4 nd:!py-2 items-center justify-center"
                >
                  Checkout
                </button>
              </div>
            </div>
          )}
        </div>

        <div
          className={`language-dropdwn !w-full flex flex-grow justify-center items-center ${
            languageState.dropdownOpen
              ? "expanded border border-accent border-opacity-55"
              : ""
          }`}
        >
          <div className="flex flex-grow h-full flex-col !w-full !items-center justify-center relative">
            <div
              onClick={() =>
                languageDispatch({ type: "SET_LANGUAGE", payload: "en-gb" })
              }
              className={`flex flex-grow ${
                showCheckmark("en-gb", languageState.language)
                  ? "bg-accent bg-opacity-10"
                  : ""
              } p-2 select-none cursor-pointer		 !h-full w-full justify-center transition duration-200`}
            >
              <span className="fi fi-gb"></span>
              <span className="mx-3 text-lg self-center">English</span>
            </div>
            <div
              onClick={() =>
                languageDispatch({ type: "SET_LANGUAGE", payload: "lv" })
              }
              className={`flex flex-grow ${
                showCheckmark("lv", languageState.language)
                  ? "bg-accent bg-opacity-10"
                  : ""
              } p-2 select-none cursor-pointer		 !h-full w-full justify-center  transition duration-200`}
            >
              <span className="fi fi-lv"></span>
              <span className="mx-3 text-lg self-center">Latvian</span>
            </div>
            <div
              onClick={() =>
                languageDispatch({ type: "SET_LANGUAGE", payload: "ru" })
              }
              className={`flex flex-grow ${
                showCheckmark("ru", languageState.language)
                  ? "bg-accent bg-opacity-10"
                  : ""
              } p-2 select-none cursor-pointer		 !h-full w-full justify-center  transition duration-200`}
            >
              <span className="fi fi-ru"></span>
              <span className="mx-3 text-lg self-center">Russian</span>
            </div>
          </div>
        </div>

        <div
          ref={overlayContentRef}
          className={`overlay-content ${
            overlayVisible
              ? "expanded sm:!w-1/2 md:!hidden border border-accent border-opacity-55"
              : ""
          }`}
        >
          {isContentVisible && (
            <div className="overlay-inner-content !mx-8 !mt-20 md:!hidden">
              <button
                onClick={toggleOverlay}
                className="rounded-full border p-3 border-accent"
              >
                <X className="overlay-close !cursor-pointer" size={20} />
              </button>
              <nav className="flex flex-col my-6 flex flex-grow">
                <div className="flex flex-col">
                  <a
                    href="/"
                    className="navbar-link hover:underline my-3 text-4xl font-bold no-underline !text-accent"
                  >
                    Home
                  </a>
                  <a
                    href="/adminka/products"
                    className="navbar-link hover:underline my-3 text-4xl font-bold no-underline !text-accent"
                  >
                    Products
                  </a>
                  <a
                    href="/shop/keycaps"
                    className="navbar-link hover:underline my-3 text-4xl font-bold no-underline !text-accent"
                  >
                    Keycaps
                  </a>
                  <a
                    href="/shop/tools"
                    className="navbar-link hover:underline my-3 text-4xl font-bold no-underline !text-accent"
                  >
                    Tools
                  </a>
                  <a
                    href="/contact"
                    className="navbar-link hover:underline my-3 text-4xl font-bold no-underline !text-accent"
                  >
                    Contact
                  </a>
                </div>

                <div className="bottomtasks absolute flex flex-row flex-grow !w-full !justify-end">
                  <MenuSeparator />
                </div>
                <div className="bottomtask absolute flex flex-row flex-grow !w-full !justify-end">
                  <div className="flex flex-row">
                    <LanguageDropdown />
                  </div>

                  <a
                    href="/cart"
                    className="navbar-link hover:underline my-2 self-center text-2xl font-bold no-underline !text-accent"
                  >
                    Cart
                  </a>
                  <a
                    href="/login"
                    className="navbar-link hover:underline my-2 self-center text-2xl font-bold no-underline ml-4 !text-accent"
                  >
                    Login
                  </a>
                </div>
              </nav>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ScreenBase;
