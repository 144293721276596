import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { injectIntl, WrappedComponentProps } from "react-intl";
import messages from "./messages";
import { fetchData } from ".";
import { useSocket } from "../../components/SocketContext";
import {
  withDataFetching,
  withSubscription,
  ScreenBase,
  ItemList,
  NoItemScreen,
  ItemBanner,
  LoadingScreen,
} from "src/components/index";

interface YourComponentProps extends WrappedComponentProps {
  data: any[] | null;
}

const YourComponent: React.FC<YourComponentProps> = ({ data, intl }) => {
  const socket = useSocket();
  const [items, setItems] = useState<any[] | null>(null);
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const location = useLocation();
  const {category } = params as any;

  const uppercaseCategory = category.charAt(0).toUpperCase() + category.slice(1);

  useEffect(() => {
    setItems(data);
  }, [data]);

  useEffect(() => {
    if (socket) {
      socket.on("changeData", () => {
        setItems((prevItems) => [...(prevItems || [])]);
      });
    }

    return () => {
      if (socket) {
        socket.off("changeData");
      }
    };
  }, [socket]);

  if (loading) return <LoadingScreen />;
  return (
    <ScreenBase title={intl.formatMessage(messages.screen.title, {category: uppercaseCategory})}>
      {items && items.length > 0 ? (
        <ItemList items={items} Component={ItemBanner} />
      ) : (
        <NoItemScreen title={intl.formatMessage(messages.no_items.title)} />
      )}
    </ScreenBase>
  );
};

const ComponentWithData = withDataFetching((state) => {
  return fetchData(state.category);
})(injectIntl(withSubscription(YourComponent, "changeData")));

export default ComponentWithData;
