import { PaymentElement } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { useLocation, useNavigate } from "react-router-dom";
import axios, { AxiosResponse } from "axios";
import withHandleResponse from "src/components/withHandleResponse";
import { injectIntl } from "react-intl";
import messages from "./messages";
import axiosInstance from "src/http-common";

interface PaymentDetails {
  handleResponse: (response: any) => AxiosResponse;
  intl: any;
}
const CheckoutForm: React.FC<PaymentDetails> = ({ handleResponse, intl }) => {
  const stripe = useStripe();
  const elements = useElements();
  const location = useLocation();
  const {
    state: { orderId },
  } = location;

  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);

    const { paymentIntent, error }: any = await stripe.confirmPayment({
      elements,
      redirect: "if_required",
    });

    if (error) {
      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message);
      } else {
        setMessage("An unexpected error occurred.");
      }
      setIsProcessing(false);
    } else if (paymentIntent.status === "succeeded") {
      // Payment succeeded, save the order or perform other actions

      const response = await axiosInstance.put("/checkout", {
        language: intl.locale,
        paymentIntentId: paymentIntent.id,
        orderId: orderId,
      });

      const data = handleResponse(response);

      setMessage("Payment succeeded! Order saved.");
      navigate("/completion", { state: { email: response.data?.email } });
      setIsProcessing(false);
    }

    setIsProcessing(false);
  };

  return (
    <div className="flex flex-grow flex-col justify-center items-center h-screen overflow-y-auto">
      <form
        id="payment-form"
        className="flex flex-col w-full my-6 mb-10 mx-4 max-w-md bg-white p-4 shadow-md rounded-lg overflow-y-auto max-h-screen"
        onSubmit={handleSubmit}
      >
        <PaymentElement id="payment-element" className="mb-4" />
        <button
          disabled={isProcessing || !stripe || !elements}
          id="submit"
          className="w-full cursor-pointer rounded-lg bg-second text-first text-base font-semibold py-3 px-10 mt-4 disabled:opacity-50"
        >
          <span id="button-text">
            {isProcessing
              ? intl.formatMessage(messages.payment_details.processing)
              : intl.formatMessage(messages.payment_details.pay_now)}
          </span>
        </button>
        {message && (
          <div
            id="payment-message"
            className="mt-4 text-center text-red-500 text-sm"
          >
            {message}
          </div>
        )}
      </form>
    </div>
  );  
};
const injectedComponent = withHandleResponse(CheckoutForm);
export default injectIntl(injectedComponent);
