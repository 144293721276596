import React, { useState } from "react";

interface CarouselProps {
  images: string[];
}

const Carousel: React.FC<CarouselProps> = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const goToNextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const goToPrevImage = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  const goToImage = (index: number) => {
    setCurrentIndex(index);
  };

  return (
    <div className="w-full max-w-screen-lg mb-4 px-4 mx-auto text-center">
      <div className="relative overflow-hidden">
        {images.length > 1 && (
          <button
            className="absolute top-1/2 left-2 transform -translate-y-1/2 bg-accent bg-opacity-70	 text-white text-2xl px-3 py-2 rounded-full cursor-pointer"
            onClick={goToPrevImage}
          >
            &#10094;
          </button>
        )}
        <img
          src={`http://localhost:8000/static/${images[currentIndex]}`}
          alt={`Slide ${currentIndex}`}
          className="w-full max-h-[300px] md:max-h-[400px]  onaspect object-contain rounded-lg"
        />
        {images.length > 1 && (
          <button
            className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-accent text-white text-2xl px-3 py-2 bg-opacity-70	 rounded-full cursor-pointer"
            onClick={goToNextImage}
          >
            &#10095;
          </button>
        )}
      </div>

      <div className="flex justify-start sm:justify-center mt-4">
        {images.map((image, index) => (
          <img
            key={index}
            src={`http://localhost:8000/static/${image}`}
            alt={`Thumbnail ${index}`}
            className={`w-20 h-20 object-cover mx-2 cursor-pointer rounded-lg transition-opacity duration-300 ${
              index === currentIndex
                ? "opacity-100 border-2 border-gray-800"
                : "opacity-60"
            }`}
            onClick={() => goToImage(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default Carousel;
