// src/http-common.ts
import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "https://api.keebstore.eu",
  headers: {
    "Content-type": "application/json",
  },
});

export default axiosInstance;
