import axios, { AxiosError } from "axios";
import axiosInstance from "src/http-common";

export const getProduct = async (glob: string) => {
  const response = await axiosInstance.get(`/product`, {
    params: { glob },
  });
  return response;
};

export const putReview = async (data: any, glob: string) => {
  try {
    const response = await axiosInstance.put(`/reviews`, data, {
      withCredentials: true,
      params: { glob: glob },
    });
    return response;
  } catch (e: any) {
    return e.response;
  }
};

export const addToCart = async (id: string, count: number) => {
  try {
    const response = await axiosInstance.post(
      "/cart",
      {
        product: {
          id,
          quantity: count,
        },
      },
      { withCredentials: true }
    );

    return response;
  } catch (e: any) {
    return e.response;
  }
};
