import { ScreenBase } from "src/components/index";
import React, { useEffect } from "react";
import { injectIntl } from "react-intl";
import messages from "./messages";
import { useLocation, useNavigate } from "react-router-dom";
import { Mail } from "react-feather";

interface CompletionProps {
  intl: any;
}

const Completion: React.FC<CompletionProps> = ({ intl }) => {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (!location.state?.email) {
      navigate("/404");
    }
  }, [location]);

  return (
    <ScreenBase title={intl.formatMessage(messages.completion.title)}>
      <div className="error-container relative flex flex-col flex-grow items-center justify-start">
        <h1 className="absolute flex flex-col !select-none inset-0 flex justify-center items-center text-9xl sm:text-11xl md:text-14xl tracking-wider opacity-10 text-accent font-semibold">
          <span>Thank</span>
          <span>you!</span>
        </h1>

        <h2 className="text-base self-center mx-4 bg-opacity-0	 bg-transparent	 md:text-lg my-8 inline-flex space-x-1">
            <span>
              {intl.formatMessage(messages.text.email_sent, {})}
              <span className="text-second"> {location.state?.email} 🎉</span>
            </span>
          </h2>
      </div>
    </ScreenBase>
  );
};

const Component = injectIntl(Completion);

export default Component;
