import { ComponentType, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AxiosResponse } from "axios";
import LoadingScreen from "./LoadingScreen";
import ErrorScreen from "./ErrorScreen";
import { useIntl } from "react-intl";
import { Notification } from "./helpers/notification";

interface WithHandleResponseProps<T> {
  handleResponse: (response: any) => AxiosResponse;
}

const withHandleResponse = <P extends WithHandleResponseProps<any>>(
  WrappedComponent: ComponentType<P>
) => {
  return (props: Omit<P, "handleResponse" | "handleError">) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [response, setResponse] = useState<AxiosResponse | null>(null);
    const intl = useIntl();

    const navigate = useNavigate();

    const handleResponse = (response: AxiosResponse) => {
      if (response.data?.error) {

      } else {
        return response;
      }
    };

    if (loading) {
      return <LoadingScreen />;
    }

    if (error) {
      return <ErrorScreen error={error} />;
    }

    return (
      <WrappedComponent
        {...(props as P)}
        handleResponse={handleResponse}
        response={response}
      />
    );
  };
};

export default withHandleResponse;
