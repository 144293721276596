import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { validatePage } from ".";
import { useForm } from "react-hook-form";
import messages from "./messages";
import { withDataFetching } from "src/components/index";
import { AdminScreenBase } from "src/components/admin/components";
import FormData from "form-data";
import { withHandleResponse } from "src/components/index";
import { Notification } from "src/components/helpers/notification";
import { useNavigate } from "react-router-dom";
import axiosInstance from "src/http-common";
import { categories } from "src/const";

interface CreateProductProps {
  data: any[] | null;
  intl: any;
  handleResponse: (response: any) => any;
}

type Characteristic = {
  [key: string]: string;
};

interface FormProps {
  name: {
    "en-gb": string;
    lv: string;
    ru: string;
  };
  description: {
    "en-gb": string;
    lv: string;
    ru: string;
  };
  category: string;
  characteristics: Characteristic[];
  family: string;
  discount: number;
  price: number;
  stock: number;
  glob: string;
  images: FileList;
}

const CreateProduct: React.FC<CreateProductProps> = ({
  data,
  intl,
  handleResponse,
}) => {
  const { register, handleSubmit } = useForm<FormProps>();
  const [files, setFiles] = useState([]) as any;
  const [familyFile, setFamilyFile] = useState<File | null>(null);
  const navigate = useNavigate();
  const [characteristics, setCharacteristics] = useState<Characteristic[]>([]);

  const handleFileChange = (event: any) => {
    setFiles(event.target.files);
  };

  const handleFamilyFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile && selectedFile.type.startsWith("image/")) {
      setFamilyFile(selectedFile);
    } else {
      Notification("Invalid image", "error");
    }
  };

  const locales = ["en-gb", "lv", "ru"];

  const onSubmit = (data: FormProps) => {
    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append("images", files[i]);
    }

    if (familyFile) {
      formData.append("familyImage", familyFile); 
    }
    
    formData.append("name", JSON.stringify(data.name));
    formData.append("description", JSON.stringify(data.description));
    formData.append("category", data.category);
    formData.append("price", data.price);
    formData.append("stock", data.stock);
    formData.append("glob", data.glob);
    formData.append("characteristics", JSON.stringify(characteristics));
    formData.append("discount", data.discount);
    formData.append("family", data.family);

    axiosInstance
      .request({
        method: "POST",
        url: "/adminka/new-product",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      })
      .then((response) => {
        const responseData = handleResponse(response);

        if (responseData) {
          Notification(
            intl.formatMessage(messages.notifications.success),
            "success"
          );

          navigate("/adminka/products");
        }
      })
      .catch((e) => {});
  };

  const handleAddCharacteristic = () => {
    setCharacteristics([...characteristics, { key: "", value: "" }]);
  };

  const handleRemoveCharacteristic = (index: number) => {
    setCharacteristics(characteristics.filter((_, i) => i !== index));
  };

  const handleCharacteristicChange = (
    index: number,
    field: keyof Characteristic,
    value: string
  ) => {
    const updatedCharacteristics = [...characteristics];
    updatedCharacteristics[index][field] = value;
    setCharacteristics(updatedCharacteristics);
  };

  return (
    <AdminScreenBase title={intl.formatMessage(messages.screen.title)}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="form mx-0 !p-6 md:!p-20 md:!py-4"
      >
        <div className="flex flex-grow flex-col md:!flex-row">
          <div className="flex flex-col flex-grow">
            <div className="product-name !text-accent font-semibold">
              {intl.formatMessage(messages.form.name)}
            </div>
            <div className="mr-4 flex flex-grow flex-col">
              {locales.map((locale, i) => (
                <input
                  className="form-input !w-full !bg-white !border !border-accent !border-opacity-25 px-2 py-3 !text-accent "
                  {...register(`name.${locale}` as any)}
                  type="text"
                  name={`name.${locale}`}
                  id={locale}
                  placeholder={locale}
                />
              ))}

              <input
                className="form-input !w-full !bg-white !border !border-accent !border-opacity-25 px-2 py-3 !text-accent "
                type="text"
                id="glob"
                min={0}
                max={48}
                placeholder="Glob"
                {...register("glob")}
              />
            </div>
          </div>
          <div className="flex flex-grow flex-col">
            <div className="product-name !text-accent font-semibold">
              {intl.formatMessage(messages.form.description)}
            </div>

            {locales.map((locale, i) => (
              <input
                className="form-input !w-full !bg-white !border !border-accent !border-opacity-25 px-2 py-3 !text-accent "
                {...register(`description.${locale}` as any)}
                type="text"
                name={`description.${locale}`}
                id={locale}
                placeholder={locale}
              />
            ))}
          </div>
        </div>

        <div className="flex flex-grow flex-col md:flex-row">
          <div className="name-box flex flex-grow flex-col w-full !mr-4">
            <div className="product-name !text-accent font-semibold">
              {intl.formatMessage(messages.form.category)}
            </div>
            <select
              className="form-input !w-full !bg-white !border !border-accent !border-opacity-25 px-2 py-3 !text-accent "
              id="category"
              {...register("category")}
            >
              {categories.map((category) => (
                <option value={category.name}>{category.name}</option>
              ))}
            </select>

            <div className="product-name !text-accent font-semibold">
              Family
            </div>

            <input
              className="form-input !w-full !bg-white !border !border-accent !border-opacity-25 px-2 py-3 !text-accent"
              type="text"
              {...register("family")}
              id="family"
              min={6}
              max={32}
              placeholder="Family"
            ></input>
          </div>

          <div className="characteristics-box flex flex-grow flex-col w-full">
            <div className="flex flex-row items-center">
              <div className="product-name !text-accent font-semibold">
                Characteristics
              </div>
              <button
                type="button"
                className="h-fit p-2 px-3 !text-accent nd:ml-2 cursor-pointer !text-base !text-first !bg-second !font-semibold !border !border-second !rounded-md items-center justify-center"
                onClick={handleAddCharacteristic}
              >
                +
              </button>
            </div>
            {characteristics.map((characteristic, index) => (
              <div key={index} className="characteristic-item flex flex-row">
                <input
                  className="form-input !w-full !bg-white !border !border-accent !border-opacity-25 px-2 py-3 !text-accent "
                  type="text"
                  value={characteristic.key}
                  onChange={(e) =>
                    handleCharacteristicChange(index, "key", e.target.value)
                  }
                  placeholder="key"
                />
                <input
                  className="form-input !w-full !bg-white !border !border-accent !border-opacity-25 px-2 py-3 !text-accent "
                  type="text"
                  value={characteristic.value}
                  onChange={(e) =>
                    handleCharacteristicChange(index, "value", e.target.value)
                  }
                  placeholder="Value"
                />
                <button
                  type="button"
                  className="btn-remove"
                  onClick={() => handleRemoveCharacteristic(index)}
                >
                  -
                </button>
              </div>
            ))}
          </div>
        </div>

        <div className="flex flex-col md:flex-row">
          <div className="name-box w-full flex flex-grow flex-col mr-4">
            <div className="product-name !text-accent font-semibold">
              {intl.formatMessage(messages.form.price)}
            </div>

            <input
              className="form-input !w-full !bg-white !border !border-accent !border-opacity-25 px-2 py-3 !text-accent "
              type="number"
              id="price"
              min={0}
              placeholder={intl.formatMessage(messages.form.price)}
              {...register("price")}
            />
            <input
              className="form-input !w-full !bg-white !border !border-accent !border-opacity-25 px-2 py-3 !text-accent "
              type="number"
              id="discount"
              placeholder={intl.formatMessage(messages.form.discount)}
              {...register("discount")}
            />
            <input
              className="form-input !w-full !bg-white !border !border-accent !border-opacity-25 px-2 py-3 !text-accent "
              type="number"
              id="stock"
              max={100}
              min={0}
              placeholder={intl.formatMessage(messages.form.stock)}
              {...register("stock")}
            />
          </div>
          <div className="name-box  w-full flex flex-grow flex-col">
            <div className="product-name !text-accent font-semibold">
              {intl.formatMessage(messages.form.image)}
            </div>
            <input
              className="form-input h-fit !w-full !bg-white !border !border-accent !border-opacity-25 px-2 py-3 !text-accent "
              type="file"
              id="images"
              name="images"
              accept="image/*"
              onChange={handleFileChange}
              multiple // Allow multiple files
            />
          </div>

          <div className="product-name !text-accent font-semibold">
            Family Image
          </div>
          <input
            className="form-input h-fit !w-full !bg-white !border !border-accent !border-opacity-25 px-2 py-3 !text-accent "
            type="file"
            id="familyImage"
            name="familyImage"
            accept="image/*"
            onChange={handleFamilyFileChange}
          />
        </div>

        <input
          className="!p-0.5 flex h-fit flex-grow my-4 nd:my-0 !text-accent nd:ml-2 cursor-pointer !text-base !text-first !bg-second !font-semibold !border !border-second !rounded-md py-2 px-4 nd:!py-2 items-center justify-center"
          type="submit"
          value={intl.formatMessage(messages.form.submit)}
        />
      </form>
    </AdminScreenBase>
  );
};

const ComponentWithHandleResponse = withHandleResponse(CreateProduct);

const Component = withDataFetching(
  validatePage,
  "admin"
)(injectIntl(ComponentWithHandleResponse));

export default Component;
