import React from "react";
import { NavLink } from "react-router-dom";
import "./index.css";

interface NavLinkProps {
  title?: string;
  navTo: string;
  icon?: React.ReactNode;
  id?: string;
  state?: any;
  className?: string;
}

const Link: React.FC<NavLinkProps> = ({
  title,
  navTo,
  icon,
  state,
  className = "",
}) => {

  const isActive = (match: any, location: any) => {
    if (!match) {
      return false;
    }
    return match.url === location.pathname;
  }

  return (
    <>
      <a
        href={navTo}
        className={`${className} no-underline mx-4 !w-full`}
        style={isActive({ url: navTo }, { pathname: window.location.pathname }) ? {
          color: "#222831",
          fontWeight: "bold"
        } : undefined}
        // style={({ isActive }) => {
        //   const activeStyles = isActive
        //     ? {
        //         color: "#222831",
        //         fontWeight: "bold"
        //       }
        //     : undefined;
        //   return activeStyles;
        // }}
      >
        <div className="!text-accent text-lg 3xl:text-xl uppercase font-base no-underline">{title}</div>
        {icon &&
          React.cloneElement(icon as React.ReactElement, {
            className: `text-accent`,
          })}
      </a>
    </>
  );
};

export default Link;
