import globalMessages from "src/globalMessages";
import { defineMessages } from "react-intl";
import { merge } from "lodash";

const messages = {
  completion: defineMessages({
    title: {
      id: "completion.title",
      defaultMessage: "Completion",
    },
  }),

  text: defineMessages({
    thank_you: {
      id: "completion.text.thank_you",
      defaultMessage: "Thank you for your purchase!",
    },
    email_sent: {
      id: "completion.text.email_sent",
      defaultMessage: "🎉 Your order has been received and an email has been sent to ",
    },
  }),
};

export default merge(globalMessages, messages);
