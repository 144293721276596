const categories = [
  { id: 0, name: "none" },
  { id: 1, name: "switches" },
  { id: 2, name: "keycaps" },
  { id: 3, name: "tools" },
];

const langs = ["en-gb", "lv", "ru"]

export { categories };
