import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { IntlProvider } from "react-intl";
import { AppProvider } from "./components/LanguageContext";
import englishMessages from "./lang/en.json";
import latvianMessages from "./lang/lv.json";
import russianMessages from "./lang/ru.json";
import { useAppContext } from "./components/LanguageContext";
import { SocketProvider } from "./components/SocketContext";
import { CartProvider } from "./components/CartCountContext";
import { OverlayProvider } from "./components/OverlayProvider";

const InnerApp = () => {
  const {
    state: { language },
    dispatch,
  } = useAppContext();

  let messages: any;


  console.log("language", language)
  if (language === "en-gb") {
    messages = englishMessages;
  } else if (language === "lv") {
    messages = latvianMessages;
  } else if (language === "ru") {
    messages = russianMessages;
  } else {
    console.log("lang reset")

    // dispatch({ type: "SET_LANGUAGE", payload: "en-gb" });
    messages = englishMessages;
  }

  return (
    <IntlProvider defaultLocale="en-gb" locale={language} messages={messages}>
      <App />
    </IntlProvider>
  );
};

const MainApp = () => {
  return (
    <React.StrictMode>
      <CartProvider>
        <OverlayProvider>
          <AppProvider>
            <SocketProvider>
              <InnerApp />
            </SocketProvider>
          </AppProvider>
        </OverlayProvider>
      </CartProvider>
    </React.StrictMode>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<MainApp />);

reportWebVitals();
