import { AxiosError } from "axios";
import globalMessages from "src/globalMessages";

export default (intl: any, error: any) => {
  if (error.response.data?.error) {
    const errorMessages: any = globalMessages.error;

    const keys: any = Object.keys(errorMessages);

    for (const key of keys) {
      const messageText = errorMessages[key].defaultMessage;
      if (error.response.data.error === messageText) {
        return intl.formatMessage(errorMessages[key]);
      }
    }
  } else {
    return error;
  }
};
