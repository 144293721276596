import React from "react";
import { injectIntl } from "react-intl";
import { editProduct, validatePage } from ".";
import { useForm } from "react-hook-form";
import { useAppContext } from "../../LanguageContext";
import { useLocation, useNavigate } from "react-router-dom";
import messages from "./messages";
import { AdminScreenBase } from "src/components/admin/components";
import { withDataFetching, withHandleResponse } from "src/components/index";
import { Notification } from "src/components/helpers/notification";
interface EditProductProps {
  id?: string;
  name?: object;
  description?: object;
  price?: number;
  stock?: number;
  image?: string;
  discount?: number;
}

interface Props {
  intl: any;
  data: any | null;
  handleResponse: (response: any) => any;
}

const EditProduct: React.FC<Props> = ({ data, intl, handleResponse }) => {
  const {
    state: { language },
    dispatch,
  } = useAppContext();
  const navigate = useNavigate();

  const location = useLocation();

  const locales = ["en-gb", "lv", "ru"];
  const {
    state: { _id, name, description, price, stock, image },
  } = location;

  const { register, handleSubmit } = useForm<EditProductProps>({
    defaultValues: {
      id: _id,
      name,
      description,
      price,
      stock,
      image,
    },
  });

  const onSubmit = async (data: EditProductProps) => {
    const response = await editProduct(data);

    const responseData = handleResponse(response);
    if (responseData) {
      Notification(
        intl.formatMessage(messages.notifications.success),
        "success"
      );
      navigate("/adminka/products");
    }
  };

  return (
    <AdminScreenBase title={intl.formatMessage(messages.screen.title)}>
      <form onSubmit={handleSubmit(onSubmit)} className="form">
        <div className="name-box">
        <div className="product-name !text-accent font-semibold">
            {intl.formatMessage(messages.form.name)}
          </div>
          <div className="name-inputs">
            {locales.map((locale, i) => (
              <input
                className="form-input px-2 py-3"
                {...register(`name.${locale}` as any)}
                type="text"
                name={`name.${locale}`}
                id={locale}
                placeholder={locale}
              />
            ))}
          </div>
          <div className="description-inputs">
          <div className="product-name !text-accent font-semibold">
              {intl.formatMessage(messages.form.description)}
            </div>

            {locales.map((locale, i) => (
              <input
                className="form-input px-2 py-3"
                {...register(`description.${locale}` as any)}
                type="text"
                name={`description.${locale}`}
                id={locale}
                placeholder={locale}
              />
            ))}
          </div>
        </div>
        <div className="name-box">
        <div className="product-name !text-accent font-semibold">
            {intl.formatMessage(messages.form.price)}
          </div>

          <input
            className="form-input px-2 py-3"
            type="number"
            id="price"
            min={0}
            placeholder={intl.formatMessage(messages.form.price)}
            {...register("price")}
          />
          <input
            className="form-input px-2 py-3"
            type="number"
            id="discount"
            min={0}
            max={100}
            placeholder={intl.formatMessage(messages.form.discount)}
            {...register("discount")}
          />
          <input
            className="form-input px-2 py-3"
            type="number"
            id="stock"
            min={0}
            placeholder={intl.formatMessage(messages.form.stock)}
            {...register("stock")}
          />
        </div>
        <input
          className="form-submit"
          type="submit"
          value={intl.formatMessage(messages.form.submit)}
        />
      </form>
    </AdminScreenBase>
  );
};

const ComponentWithHandleResponse = withHandleResponse(EditProduct);
const Component = withDataFetching(
  validatePage,
  "admin"
)(injectIntl(ComponentWithHandleResponse));
export default Component;
