import React, { useEffect, useState, useCallback } from "react";
import { injectIntl } from "react-intl";
import { getCartItems, onDelete, onSave } from ".";
import {
  ScreenBase,
  NoItemScreen,
  LoadingScreen,
  CartItem,
  withHandleResponse,
  withDataFetching,
} from "src/components/index";
import messages from "./messages";
import { useNavigate } from "react-router-dom";
import { Notification } from "src/components/helpers/notification";
import { CreditCard } from "react-feather";

interface Props {
  intl: any;
  data: {
    items: any[];
    totalAfterDiscount: number;
    totalBeforeDiscount: number;
  };
  handleResponse: (response: any) => any;
}

const CartPage: React.FC<Props> = ({ intl, data, handleResponse }) => {
  const [items, setItems] = useState(data?.items || []);
  const [message, setMessage] = useState("");
  const [total, setTotal] = useState({
    totalAfterDiscount: data.totalAfterDiscount,
    totalBeforeDiscount: data.totalBeforeDiscount,
  });
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setItems(data.items);
  }, [data.items]);

  const deleteItem = async (itemId: string) => {
    setLoading(true);
    const resp = await onDelete(itemId);
    const respData = handleResponse(resp);
    if (respData) {
      setTotal({
        totalAfterDiscount: respData.data.totalAfterDiscount,
        totalBeforeDiscount: respData.data.totalBeforeDiscount,
      });
      setItems(respData.data.items);
      setLoading(false);
    }
  };

  // todo : rewrite to save all items at once // start here and then go to the backend
  const handleSave = async () => {

    setLoading(true);
    const resp: any = await onSave(items);
    const respData = handleResponse(resp);
    if (respData) {
      setTotal({
        totalAfterDiscount: respData.data.totalAfterDiscount,
        totalBeforeDiscount: respData.data.totalBeforeDiscount,
      });
      setItems(respData.data.items);
      setLoading(false);
    }
  };

  const handleItemChange = useCallback(
    (itemId: string, quantity: number) => {
      setItems((prevItems) =>
        prevItems.map((item) =>
          item.itemId === itemId ? { ...item, quantity } : item
        )
      );
    },
    [setItems]
  );

  if (loading) return <LoadingScreen />;

  return (
    <ScreenBase title={intl.formatMessage(messages.cart_page.title)}>
      {items && items.length > 0 ? (
        <div className="flex flex-col flex-grow w-full lg:flex-row items-start border-spacing-y-16	py-6 xs:px-2 md:px-8">
          <div className="flex flex-grow flex-col w-full px-6">
            <div className="flex flex-row flex-grow w-full tracking-wide justify-between !font-semibold my-3 px-3">
              <div className="text-base text-accent ">Product</div>
              <div className="md:ml-20 text-base text-accent">Quantity</div>
              <div className="text-base text-accent hidden md:flex">Total</div>
            </div>
            <div className="flex flex-col flex-grow w-full justify-start items-start !border-y !border-accent !border-opacity-15">
              {items.map((item: any) => (
                <CartItem
                  item={item}
                  key={item.id}
                  options={{
                    onDelete: deleteItem,
                    onSave: handleSave,
                    onChange: handleItemChange,
                  }}                />
              ))}
            </div>
            <div onClick={() => {
              handleSave()
            }} className="text-first cursor-pointer font-semibold text-base self-end mt-8 bg-second rounded px-3 py-2.5">
              Save changes
            </div>
          </div>

          <div className="flex flex-col flex-grow w-full lg:!w-6/12 h-full px-6 xs:my-4 lg:my-0">
            <div className="flex flex-col my-8 lg:my-0 !w-full border-1 !h-fit border-accent border-opacity-15 rounded-lg xs:py-6 xs:px-6 sm:py-10 sm:px-10 lg:py-8 lg:px-8">
              <div className="flex flex-col">
                <div className="flex flex-row !w-full rounded-lg mb-8">
                  <div className="flex flex-col flex-grow">
                    <div className="text-accent xs:text-base lg:text-lg opacity-75">
                      Subtotal
                    </div>
                    <div className="text-accent xs:text lg:text-lg opacity-75">
                      Discount
                    </div>
                    <div className="text-accent xs:text-xl lg:text-2xl font-bold mt-1 mb-2">
                      Total
                    </div>
                    <div className="text-accent text-base opacity-75">
                      Tax included
                    </div>
                  </div>

                  <div className="flex flex-col flex-grow !items-end">
                    <div className="text-accent xs:text-base lg:text-lg opacity-75">
                      {total.totalBeforeDiscount.toFixed(2)}€
                    </div>
                    <div className="text-accent xs:text-base lg:text-lg opacity-75">
                      {(
                        total.totalBeforeDiscount - total.totalAfterDiscount
                      ).toFixed(2)}
                      €
                    </div>
                    <div className="text-accent xs:text-xl lg:text-2xl mt-1 mb-2 font-bold">
                      {total.totalAfterDiscount.toFixed(2)}€
                    </div>
                  </div>
                </div>
                <textarea
                  className="w-full h-20 bg-white text-accent border-1 border-accent border-opacity-25 xs:text-sm xs:p-2 sm:p-3 lg:p-2 rounded-lg"
                  placeholder="Add a note to your order"
                  maxLength={200}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
              </div>

              <div
                onClick={() =>
                  navigate("/confirm-checkout", {
                    state: {
                      message,
                    },
                  })
                }
                className="flex flex-row cursor-pointer rounded-lg bg-second text-first text-base items-center font-semibold text-center justify-center py-3 mt-8"
              >
                <CreditCard className="mr-2" />
                Checkout
              </div>
            </div>
          </div>
        </div>
      ) : (
        <NoItemScreen title={intl.formatMessage(messages.no_items_in_cart)} />
      )}
    </ScreenBase>
  );
};

const ComponentWithHandleResponse = withHandleResponse(CartPage);
const ComponentWithData = withDataFetching(getCartItems)(
  injectIntl(ComponentWithHandleResponse)
);

export default ComponentWithData;