import axiosInstance from "src/http-common";

interface Product {
  name: string;
  price: number;
  description: string;
  category: string;
  image: string;
  id: string;
}

export const fetchData = async (id: string) => {
  const response = await axiosInstance.get("/product");
  return response;
};

export const editProduct = async (data: any) => {
  const response = await axiosInstance.put(
    "/product",
    {
      product: data,
    },
    { withCredentials: true }
  );

  return response;
};

export const validatePage = async () => {
  const response = await axiosInstance.get("/adminka/validate-admin", {
    withCredentials: true,
  });

  return response;
};
