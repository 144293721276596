import React from "react";
import { useForm } from "react-hook-form";
import { withDataFetching, ScreenBase } from "src/components/index";
import { login, validate } from ".";
import { useNavigate } from "react-router-dom";
import { injectIntl, useIntl } from "react-intl";
import messages from "./messages";

interface LoginProps {}

interface FormProps {
  email: string;
  password: string;
}

const Login: React.FC<LoginProps> = ({}) => {
  const { register, handleSubmit } = useForm<FormProps>();
  const navigate = useNavigate();
  const intl = useIntl();
  const onSubmit = (data: FormProps) => {
    login(data.email, data.password, navigate);
  };

  return (
    <ScreenBase title={intl.formatMessage(messages.screen.title)}>
      <div className="flex flex-grow justify-center items-center !bg-first">
        <form className="flex flex-grow flex-col" onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <div className="form-label-title !text-accent !opacity-75">
              {intl.formatMessage(messages.form.email)}
            </div>
            <input
              className="form-input !text-accent px-2 py-3 !border-accent !border-opacity-75 !bg-first"
              {...register("email")}
              type="email"
              name="email"
              id="email"
              placeholder={intl.formatMessage(messages.form.email)}
            />
          </div>
          <div className="form-group">
            <div className="form-label-title !text-accent opacity-75">
              {intl.formatMessage(messages.form.password)}
            </div>
            <input
              className="form-input px-2 py-3 !border-accent !border-opacity-75 !bg-first"
              {...register("password")}
              type="password"
              name="password"
              id="password"
              placeholder={intl.formatMessage(messages.form.password)}
            />
          </div>
          <button
            className="!p-0.5 flex my-2 nd:my-0 !text-accent cursor-pointer !text-base !text-first !bg-second !font-semibold !border !border-second !rounded-md py-3 px-4 nd:!py-2 items-center justify-center"
            type="submit"
          >{intl.formatMessage(messages.form.submit)}
          </button>
        </form>
      </div>
    </ScreenBase>
  );
};

export default withDataFetching(validate, "admin")(Login);
